/* global clearInterval, console, setInterval */
const config = require("pa_config");
const hostUrl = config.API_URL;
// const hostUrl = "https://alfa.plutusanalytics.com/api/";
const validator = require("validator");
const apiKey = localStorage.getItem("appKey");
const apiSecret = localStorage.getItem("appSecret");
const passProtect = "plutusAnalytics";

function ToDateStr(dateNum) {
  // let d = new Date((dateNum- (25567+2 )) * 86400 * 1000);
  let d = new Date((dateNum- 25569) * 86400000);
  return d.toISOString().substring(0,10);
}

async function LockSheet() {
  await Excel.run(async (ctx) => {
    let curSheet = ctx.workbook.worksheets.getActiveWorksheet();
    curSheet.load("protection/protected");
    await ctx.sync();
    if (!curSheet.protection.protected){
      var options = {
        allowAutoFilter : false,
        allowDeleteColumns : false,
        allowDeleteRows : false,
        allowEditObjects : false,
        allowEditScenarios : false,
        allowFormatCells : false,
        allowFormatColumns : false,
        allowFormatRows : false,
        allowInsertColumns : false,
        allowInsertHyperlinks : false,
        allowInsertRows : false,
        allowPivotTables : false,
        allowSort : false,
        selectionMode: "None"
      }
      curSheet.protection.protect(options, passProtect);
    }
    console.log("locked");
  });
}
async function UnlockSheet() {
  await Excel.run(async (ctx) => {
    let curSheet = ctx.workbook.worksheets.getActiveWorksheet();
    curSheet.load("protection/protected");
    await ctx.sync();
    //if (curSheet.protection.protected){
      curSheet.protection.unprotect(passProtect);
    //}
    console.log("unlocked");
  }).catch((err)=>{
    console.log(err);
  });
}  
async function FillData(startCol, endCol, startRow, rowCount, data){
  console.log(startCol, endCol, startRow, rowCount, data);
  await Excel.run(async (ctx) => {
    let curSheet = ctx.workbook.worksheets.getActiveWorksheet();
    curSheet.load("protection/protected");
    await ctx.sync();
    if (curSheet.protection.protected){
      curSheet.protection.unprotect(passProtect);
    }
    // Clear rows, "8:11"
    let rowsRange = curSheet.getRange(startRow.toString() + ":" + (startRow + rowCount - 1).toString());
    rowsRange.clear(Excel.ClearApplyTo.contents);
    // Fill data, "A8:k11"
    let showDataRange = curSheet.getRange(startCol + startRow.toString() + ":" + endCol + (startRow + rowCount - 1).toString());
    showDataRange.values = data;
    // console.log(curSheet.protection.protected);
    await ctx.sync(); 
    
  }).catch((err)=>{
    console.log(err);
  });
}

// Calculate mbs
function MBS(valuationDate, settlementDate, CUSIPS, priceOrOASs, invocation, calculateItems = null, mbsMethod = "MBS", givenPrice = false, shock = 0, vol_type="SVSLN", discounting="TSY") {
  try {
    var cellAddress = invocation.address.split("!");    // sheet1!A9
    const apiUrl = hostUrl + "mbs/umbs/";
    const CAL_ITEMS_TITLE = ["OAC", "OAD", "WAL", "Spread Duration", "Life CPR", "ZV Spread", "Vol Up 10", "Yield"];//For KRD, KRD1,KRD2,...,KRD7
    const CAL_FIELD_ITEMS = ["oac", "oad", "wal", "sprdur", "lcpr", "zvspread", "vol_up_10", "yield"];
    const VOL_TYPE = ["SV", "SVSLN"];
    const DISCOUNTING = ["TSY", "SOFR"];
    if (vol_type == null){
      vol_type = "SVSLN";
    }
    else if (!VOL_TYPE.includes(vol_type)){
      throw Error('The argument "vol_type" must be one of ["SV", "SVSLN"].');
    }

    if (discounting == null){
      discounting = "TSY"
    }
    else if (!DISCOUNTING.includes(discounting)){
      throw Error('The argument "discounting" must be one of ["TSY", "SOFR"].');
    }
    // Lock current sheet.
    return LockSheet().then((res)=>{
      // Prepare post data for submitting tasks
      if (!shock){
        shock = 0;
      }
      var calItems = [];
      var calItemsTitle = [];
      var krds = [];
      if (calculateItems == null){
        calItems = CAL_FIELD_ITEMS;
        calItemsTitle = CAL_ITEMS_TITLE
      }
      else{
        calculateItems.forEach(r => {
          r.forEach(c => {
            let c_lowwer = c.toLowerCase();
            let idx = CAL_FIELD_ITEMS.indexOf(c_lowwer);
            let c_upper = c.toUpperCase();
            // console.log(c_upper);
            if (idx >= 0 && !calItems.includes(c_lowwer)) {
              calItems.push(c_lowwer);
              calItemsTitle.push(CAL_ITEMS_TITLE[idx]);
            }
            else if (c_upper.startsWith("KRD") && !krds.includes(c_upper)){
              krds.push(c_upper);
            }
          });
        });
      }
      if (mbsMethod == null){
        mbsMethod = "MBS";
      }
      else{
        mbsMethod = mbsMethod.toUpperCase();
      }
      var cusipArr = [];
      CUSIPS.forEach(r => {
        r.forEach(c => {
          cusipArr.push(c);
        });
      });
      var priceoroasArr = [];
      priceOrOASs.forEach(r => {
        r.forEach(c => {
          priceoroasArr.push(c);
        });
      });
      var re = [];
      re.push(["No.", "CUSIP", "Price", "OAS"].concat(calItemsTitle).concat(krds));
      if (cusipArr.length != priceoroasArr.length){
        throw Error("The count of CUSIPs must be the same as the count of OAS.");
      }
      var batchTask = {
        need_notify: false,
        use_own_data: false,
        use_own_hpi: false,
        use_cusip: true,
        given_price: givenPrice,
        valuation_date: ToDateStr(valuationDate),
        mbs_method: mbsMethod,
        checked_oac: calItems.includes("oac"),
        checked_oad: calItems.includes("oad"),
        checked_wal: calItems.includes("wal"),
        checked_sprdur: calItems.includes("sprdur"),
        checked_lcpr: calItems.includes("lcpr"),
        checked_zvspread: calItems.includes("zvspread"),
        checked_vol_up_10: calItems.includes("vol_up_10"),
        checked_yield: calItems.includes("yield"),
        vol_type: vol_type,
        discounting: discounting,
        checked_krd: krds.length > 0 ? krds : null,
        tasks: []
      };
      var sd = ToDateStr(settlementDate);
      for (let i = 0; i < CUSIPS.length; i++){
        let task = {
          row_id: i.toString(),
          settlement_date: sd,
          cusip: cusipArr[i]
        }
        if (shock != 0){
          task['shocks'] = [shock];
        }
        if (givenPrice) {
          task["price"] = priceoroasArr[i];
        } else{
          task["oas"] = priceoroasArr[i];
        }
        let reItem = [i + 1, cusipArr[i], "", ""];
        calItems.forEach(ci => {
          reItem.push("")
        });
        krds.forEach(ci => {
          reItem.push("")
        });
        re.push(reItem);
        batchTask.tasks.push(task);
      };

      console.log(re);
      // console.log(batchTask);
      // UnlockSheet();
      // return 0;
      
      // Submit tasks to plutus API, and get result
      if (batchTask.tasks.length <= 0){
        throw Error("No tasks submitted!");
      } else {
        //Send batch task request
        let xhttp = new XMLHttpRequest();
        let errMsg = "";
        return new Promise(function (resolve, reject){
          xhttp.onreadystatechange = function(){
            if (xhttp.readyState !== 4) return;
            if (xhttp.status >= 400){
              errMsg += "Status code: " + xhttp.status + "; \r\n" + xhttp.responseText;
              reject(errMsg);
            } else {
              let payload = JSON.parse(xhttp.responseText);

              if (payload["batch_id"]){
                batchTask["batch_id"] = payload["batch_id"];
                let taskCount = 0;
                batchTask.tasks.forEach(t=>{
                  let row_id = t.row_id;
                  if (payload.tasks[row_id]){
                    if (validator.isUUID(payload.tasks[row_id].toString())){
                      t["task_id"] = payload.tasks[row_id];
                      taskCount++;
                    }
                    else{
                      t["message"] = payload.tasks[row_id].toString();
                      re[parseInt(row_id) + 1][2] = t["message"];
                    }
                  }              
                });
                // console.log(batchTask, apiUrl);

                // Get calculate result
                per_page = 100;
                total_page = Math.ceil(taskCount / per_page);
                let base_query = apiUrl + "?batch_id=" + batchTask["batch_id"] + (shock == 0 ? "" : ("&shock=0&shock=" + shock.toString())) + "&per_page=" + per_page.toString();
                let queryCount = 0;         // Query count.
                let completedCount = 0;     // Completed task count 
                let refreshInterval = 30000; // Refresh data interval(microsecond).
                // console.log(apiUrl, base_query);
                // UnlockSheet();
                function queryResult(){
                  if (queryCount > 50 || completedCount >= taskCount){
                    return;
                  }
                  queryCount++;
                  completedCount = 0; 
                  var handledPageCount = 0;
                  for (let cur_page = 1; cur_page <= total_page; cur_page ++){
                    console.log("Page " + cur_page.toString() + " Start!"); 
                    let query = base_query + "&page=" + cur_page.toString();
                    let xhrResult = new XMLHttpRequest();
                    xhrResult.onreadystatechange = function(){
                      if (xhrResult.readyState !== 4) return;
                      // console.log(xhttp);
                      if (xhrResult.status < 400) {
                        let data = JSON.parse(xhrResult.responseText);
                        if (Array.isArray(data) && data.length > 0){
                          // Get computed result
                          base_tasks = data.filter((r) => r.shock == 0);
                          shock_tasks = data.filter((r) => r.shock != 0);
                          // console.log(base_tasks, shock_tasks);
                          base_tasks.forEach(tr => {
                            // console.log(tr);
                            if (tr.status == "SUCCESS"){
                              // When status of base task is cuccess, handle shock task data.
                              let st = shock_tasks.find((element) => element.base_shock_task_id == tr.id)
                              if (st === undefined){
                                st = tr;
                              } 
                              if (st.status == "SUCCESS" || st.status == "FAILURE"){
                                let t = batchTask.tasks.find((element) => element.task_id == tr.id);
                                if (t && t["task_id"]){
                                  completedCount++;
                                  t["status"] = st.status;
                                  let rowId = parseInt(t.row_id) + 1;
                                  if (st.status == "SUCCESS"){
                                    re[rowId][3] = st.oas;
                                    re[rowId][2] = st.price;
                                    for (let i = 0; i < calItems.length; i++){
                                      re[rowId][i + 4] = st[calItems[i] == "yield" ? "yield_val" : calItems[i]];
                                    }
                                    if (krds.length > 0){
                                      krd_vals = st['krd'];
                                      start = 4 + calItems.length;
                                      for (let i = 0; i < krds.length; i++){
                                        re[rowId][i + start] = krd_vals[i];
                                      }
                                    }
                                  } else if (tr.status == "FAILURE"){
                                    t["result"] = null;
                                    re[rowId][2] = "Calculate failed!";
                                  }
                                }
                              }
                            } else if (tr.status == "FAILURE"){
                              // When status of base task is failure, do not find shock task data.
                              let t = batchTask.tasks.find((element) => element.task_id == tr.id);
                              completedCount++;
                              t["status"] = "FAILURE";
                              let rowId = parseInt(t.row_id) + 1;
                              t["result"] = null;
                              re[rowId][2] = "Calculate failed!";
                            }
                          });
                          
                          // Show data to current sheet
                          console.log(completedCount, taskCount);
                          if (completedCount >= taskCount){
                            // Get show area
                            let rowCount = re.length;
                            let colCount = re[0].length - 1;
                            let startRow = /\d+/.exec(cellAddress[1])[0];
                            let startCol = /[A-Z]+/.exec(cellAddress[1])[0];
                            let endCol = startCol;
                            let colEndNum = endCol[endCol.length - 1].charCodeAt();
                            colEndNum += colCount - 1;
                            if (colEndNum > 90){
                              colEndNum -= 25; //colEndNum - 90 + 65;
                              if (startCol.length == 1){
                                endCol = "A" + String.fromCharCode(colEndNum);
                              } else if (col.length > 1){
                                // Only carry 1 bit.
                                let colNum = endCol[endCol.length - 2].charCodeAt();
                                colNum += 1;
                                endCol = String.fromCharCode(colNum) + String.fromCharCode(colEndNum);
                              }
                            } else {
                              if (endCol.length == 1){
                                endCol = String.fromCharCode(colEndNum);
                              } else if (col.length > 1){
                                endCol = endCol.substring(0, endCol.length - 1) + String.fromCharCode(colEndNum);
                              }
                            }
                            // console.log(re);
                            let fileData = re.map(function(val){
                              return val.slice(1);
                            });
                            FillData(startCol, endCol, parseInt(startRow) + 1, rowCount, fileData).finally(()=>{
                              handledPageCount++;
                              //resolve("The calculation is completed.");
                              resolve("");
                            });
                          }
                        }
                      }
                      handledPageCount++;
                      if (handledPageCount >= total_page){
                        if (queryCount > 50){
                          reject("Over 50 queries were made.");
                        } else if (completedCount < taskCount){
                          setTimeout(() => {
                            queryResult();
                          }, refreshInterval);
                        }
                      }
                      console.log(cur_page.toString() + "finished!");
                    };
                    xhrResult.open("GET", query, true);
                    xhrResult.setRequestHeader("x-app-key", apiKey);
                    xhrResult.setRequestHeader("x-app-secret", apiSecret);
                    xhrResult.send(); 
                  };
                  // console.log(queryCount, tryCount, completedCount);
                };
                setTimeout(() => {
                  queryResult();
                }, refreshInterval);
              } else {
                errMsg += "Message: " + xhttp.responseText + "\r\n";
                reject(errMsg);
              }
            }
          }
          xhttp.open("POST", apiUrl, true);
          xhttp.setRequestHeader("Content-Type", "application/json");
          xhttp.setRequestHeader("x-app-key", apiKey);
          xhttp.setRequestHeader("x-app-secret", apiSecret);
          xhttp.send(JSON.stringify(batchTask));

        }).finally(()=>{
          UnlockSheet();
        });
      }      
    });
  } catch (error) {
    UnlockSheet();
    return error.toString();
  }
}

/**
 * Calculate MBS Price Given OAS.
 * @customfunction 
 * @param {string} valuationDate Valuation Date
 * @param {string} settlementDate Settlement Date
 * @param {string[][]} CUSIPS CUSIP cells
 * @param {number[][]} OASs OAS cells
 * @param {number} [shock] Shock cell, shock for all tasks in the batch
 * @param {string[][]} [calculateItems] Calculate items, items should be: oac,oad,wal,sprdur,lcpr,zvspread,vol_up_10. Default: ["oac","oad","wal","sprdur","lcpr","zvspread","vol_up_10","krd1","krd2",...,"krd7"]
 * @param {string} [mbsMethod] MBS method, method should be: mbs,io,po. Default: "mbs"
 * @param {string} [vol_type] VOL type, type should be: SV, SVSLN. Default: "SVSLN"
 * @param {string} [discounting] Discounting, should be: TSY, SOFR. Default: "TSY"
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns 
 * @requiresAddress
 */
 function MBS_Price(valuationDate, settlementDate, CUSIPS, OASs, shock, calculateItems, mbsMethod, vol_type, discounting, invocation) {
  return MBS(valuationDate, settlementDate, CUSIPS, OASs, invocation, calculateItems, mbsMethod, false, shock, vol_type, discounting);
 }

 /**
 * Calculate MBS OAS Given Price.
 * @customfunction 
 * @param {string} valuationDate Valuation Date
 * @param {string} settlementDate Settlement Date
 * @param {string[][]} CUSIPS CUSIP cells
 * @param {number[][]} prices Price cells
 * @param {number} [shock] Shock cell, shock for all tasks in the batch
 * @param {string[][]} [calculateItems] Calculate items, items should be: oac,oad,wal,sprdur,lcpr,zvspread,vol_up_10. Default: ["oac","oad","wal","sprdur","lcpr","zvspread","vol_up_10","krd1","krd2",...,"krd7"]
 * @param {string} [mbsMethod] MBS method, method should be: mbs,io,po. Default: "mbs"
 * @param {string} [vol_type] VOL type, type should be: SV, SVSLN. Default: "SVSLN"
 * @param {string} [discounting] Discounting, should be: TSY, SOFR. Default: "TSY"
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns 
 * @requiresAddress
 */
function MBS_OAS(valuationDate, settlementDate, CUSIPS, prices, shock, calculateItems, mbsMethod, vol_type, discounting, invocation) {
  // console.log("valuationDate: ", valuationDate);
  // console.log("settlementDate: ", settlementDate);
  // console.log("CUSIPS: ", CUSIPS);
  // console.log("prices: ", prices);
  // console.log("shock: ", shock);
  // console.log("calculateItems: ", calculateItems);
  // console.log("mbsMethod: ", mbsMethod);
  // console.log("vol_type: ", vol_type);
  // console.log("discounting: ", discounting);
  // console.log("invocation: ", invocation);
  return MBS(valuationDate, settlementDate, CUSIPS, prices, invocation, calculateItems, mbsMethod, true, shock, vol_type, discounting);
}

const FIELD_INFO = {
  "price": "Price",
  "oas": "OAS",
  "yield_val": "Yield",
  "duration": "Duration",
  "convexity": "Convexity",
  "yield_maturity": "Yield to Maturity",
  "yield_call": "Yield to Call",
  "discount_margin": "Discount Margin"
}

function Rate_Prod_Calculate(batchTask, apiUrl, cellAddress, resultItemNames=[]){
  try {
    if (resultItemNames.length <= 0){
      resultItemNames = ["price", "duration", "convexity"];
    }
    var re = [];
    // re.push(["No.", "Price", "Duration", "Convexity"]);
    var rei = ["No."];
    resultItemNames.forEach(rin => {
      rei.push(FIELD_INFO[rin]);
    })
                                                                                                        re.push(rei);
    console.log(re, resultItemNames);
    // Result lines
    for (let i = 0; i < batchTask.tasks.length; i++){
      let reItem = [i + 1];
      resultItemNames.forEach(ci => {
        reItem.push("")
      });
      re.push(reItem);
    };
    //Send batch task request
    let xhttp = new XMLHttpRequest();
    let errMsg = "";
    return new Promise(function (resolve, reject){
      xhttp.onreadystatechange = function(){
        if (xhttp.readyState !== 4) return;
        if (xhttp.status >= 400){
          errMsg += "Status code: " + xhttp.status + "; \r\n" + xhttp.responseText;
          reject(errMsg);
        } else {
          let payload = JSON.parse(xhttp.responseText);
          // console.log(payload);
          if (payload["batch_id"]){
            batchTask["batch_id"] = payload["batch_id"];
            let taskCount = 0;
            batchTask.tasks.forEach(t=>{
              let row_id = t.row_id;
              if (payload.tasks[row_id]){
                if (Array.isArray(payload.tasks[row_id])){
                  t["message"] = JSON.stringify(payload.tasks[row_id]);
                  re[parseInt(row_id) + 1][1] = t["message"];
                }
                else if (validator.isUUID(payload.tasks[row_id])){
                  t["task_id"] = payload.tasks[row_id];
                  taskCount++;
                }
                else{
                  t["message"] = payload.tasks[row_id];
                  re[parseInt(row_id) + 1][1] = t["message"];
                }
              }              
            });
            // console.log(batchTask);

            // Get calculate result
            per_page = 500;
            total_page = Math.ceil(taskCount / per_page);
            let base_query = apiUrl + "?batch_id=" + batchTask["batch_id"] + "&per_page=" + per_page.toString();
            let queryCount = 0;         // Query count.
            let completedCount = 0;     // Completed task count 
            let refreshInterval = 5000; // Refresh data interval(microsecond).
            // console.log(query);

            function queryResult(){
              if (queryCount > 50 || completedCount >= taskCount){
                return;
              }
              queryCount++;
              completedCount = 0; 
              var handledPageCount = 0;
              for (let cur_page = 1; cur_page <= total_page; cur_page ++){
                // console.log(cur_page.toString() + "Start!");
                let query = base_query + "&page=" + cur_page.toString();
                let xhrResult = new XMLHttpRequest();
                xhrResult.onreadystatechange = function(){
                  if (xhrResult.readyState !== 4) return;
                  if (xhrResult.status < 400) {
                    let data = JSON.parse(xhrResult.responseText);
                    if (Array.isArray(data) && data.length > 0){
                      // Get computed result
                      data.forEach(tr => {
                        if (tr.status == "SUCCESS" || tr.status == "FAILURE"){
                          batchTask.tasks.some(t => {
                            if (t["task_id"] && t.task_id == tr.id){
                              completedCount++;
                              t["status"] = tr.status;
                              let rowId = parseInt(t.row_id) + 1;
                              if (tr.status == "SUCCESS"){
                                for (let i = 0; i < resultItemNames.length; i++){
                                  re[rowId][i + 1] = tr[resultItemNames[i]];
                                }
                              }
                              else if (tr.status == "FAILURE"){
                                t["result"] = null;
                                re[rowId][1] = "Calculate failed!"
                              }
                            }
                          });
                        }
                      });
                      
                      // Show data to current sheet
                      // console.log(completedCount, taskCount);
                      if (completedCount >= taskCount){
                        // Get show area
                        let rowCount = re.length;
                        let colCount = re[0].length - 1;
                        let startRow = /\d+/.exec(cellAddress[1])[0];
                        let startCol = /[A-Z]+/.exec(cellAddress[1])[0];
                        let endCol = startCol;
                        let colEndNum = endCol[endCol.length - 1].charCodeAt();
                        colEndNum += colCount - 1;
                        if (colEndNum > 90){
                          colEndNum -= 25; //colEndNum - 90 + 65;
                          if (startCol.length == 1){
                            endCol = "A" + String.fromCharCode(colEndNum);
                          } else if (col.length > 1){
                            // Only carry 1 bit.
                            let colNum = endCol[endCol.length - 2].charCodeAt();
                            colNum += 1;
                            endCol = String.fromCharCode(colNum) + String.fromCharCode(colEndNum);
                          }
                        } else {
                          if (endCol.length == 1){
                            endCol = String.fromCharCode(colEndNum);
                          } else if (col.length > 1){
                            endCol = endCol.substring(0, endCol.length - 1) + String.fromCharCode(colEndNum);
                          }
                        }
                        // console.log(re);
                        let fileData = re.map(function(val){
                          return val.slice(1);
                        });
                        FillData(startCol, endCol, parseInt(startRow) + 1, rowCount, fileData).finally(()=>{
                          handledPageCount++;
                          //resolve("The calculation is completed.");
                          resolve("");
                        });
                      }
                    }
                  }
                  handledPageCount++;
                  if (handledPageCount >= total_page){
                    if (queryCount > 50){
                      reject("Over 50 queries were made.");
                    } else if (completedCount < taskCount){
                      setTimeout(() => {
                        queryResult();
                      }, refreshInterval);
                    }
                  }
                  // console.log(cur_page.toString() + "finished!");
                };
                xhrResult.open("GET", query, true);
                xhrResult.setRequestHeader("x-app-key", apiKey);
                xhrResult.setRequestHeader("x-app-secret", apiSecret);
                xhrResult.send();
              };
              // console.log(queryCount, tryCount, completedCount);
            };
            setTimeout(() => {
              queryResult();
            }, refreshInterval);
          } else {
            errMsg += "Message: " + xhttp.responseText + "\r\n";
            reject(errMsg);
          }
        }
      }
      xhttp.open("POST", apiUrl, true);
      xhttp.setRequestHeader("Content-Type", "application/json");
      xhttp.setRequestHeader("x-app-key", apiKey);
      xhttp.setRequestHeader("x-app-secret", apiSecret);
      xhttp.send(JSON.stringify(batchTask));

    }).finally(()=>{
      UnlockSheet();
    });    
  } catch (error) {
    UnlockSheet();
    return error.toString();
  }

}

 /**
 * European Callable Bond. 
 * @customfunction 
 * @param {string} valuationDate Valuation Date
 * @param {number[][]} prices Price cells
 * @param {number[][]} lockouts Lockout cells
 * @param {number[][]} maturities Maturity cells
 * @param {number[][]} coupons Coupon cells
 * @param {number[][]} intervals Interval cells
 * @param {string} [vol_type] VOL type, type should be: SV, SVSLN. Default: "SVSLN"
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns 
 * @requiresAddress
 */
function European_Callable(valuationDate, prices, lockouts, maturities, coupons, intervals, vol_type, invocation) {
  // Lock current sheet.
  return LockSheet().then((res)=>{
    try {
      const apiUrl = hostUrl + "fixedincomeproduct/e_callable/";
      var cellAddress = invocation.address.split("!");    // sheet1!A9
      var priceArr = [];
      prices.forEach(r => {
        r.forEach(c => {
          priceArr.push(c);
        });
      });
      var lockoutArr = [];
      lockouts.forEach(r => {
        r.forEach(c => {
          lockoutArr.push(c);
        });
      });
      var maturityArr = [];
      maturities.forEach(r => {
        r.forEach(c => {
          maturityArr.push(c);
        });
      });

      var couponArr = [];
      coupons.forEach(r => {
        r.forEach(c => {
          couponArr.push(c);
        });
      });

      var intervalArr = [];
      intervals.forEach(r => {
        r.forEach(c => {
          intervalArr.push(c);
        });
      });

      if (lockoutArr.length != maturityArr.length || 
          lockoutArr.length != couponArr.length || 
          lockoutArr.length != intervalArr.length || 
          lockoutArr.length != priceArr.length){
        throw Error("The lengths of the task's parameters are not equal.")
      }
      var batchTask = {
        need_notify: false,
        use_own_data: false,
        valuation_date: ToDateStr(valuationDate),
        vol_type: vol_type == null ? "SVSLN" : vol_type,
        tasks: []
      };
      for (let i = 0; i < lockoutArr.length; i++){
        var task = {
          row_id: i.toString(),
          price: priceArr[i],
          lockout: lockoutArr[i],
          maturity: maturityArr[i],
          coupon: couponArr[i],
          interval: intervalArr[i]
        };
        batchTask.tasks.push(task);
      };
      //return JSON.stringify(batchTask);

      // Submit tasks to plutus API, and get result
      if (batchTask.tasks.length <= 0){
        throw Error("No tasks submitted!");
      } else {
        return Rate_Prod_Calculate(batchTask, apiUrl, cellAddress, ['oas', 'duration', 'convexity', 'yield_maturity', 'yield_call']);
      }      
    } catch (error) {
      UnlockSheet();
      return error.toString();
    }
  });
}

/**
 * Fixed Rate Bullet. 
 * @customfunction 
 * @param {string} valuationDate Valuation Date
 * @param {number[][]} prices Price cells
 * @param {number[][]} coupons Coupon cells
 * @param {number[][]} maturities Maturity cells
 * @param {number[][]} intervals Coupon Interval cells
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns 
 * @requiresAddress
 */
function Fixed_Rate_Bullet(valuationDate, prices, coupons, maturities, intervals, invocation) {

  // Lock current sheet.
  return LockSheet().then((res)=>{
    try {
      const apiUrl = hostUrl + "fixedincomeproduct/fixed_rate_bullet/";
      var cellAddress = invocation.address.split("!");    // sheet1!A9
      var priceArr = [];
      prices.forEach(r => {
        r.forEach(c => {
          priceArr.push(c);
        });
      });

      var couponArr = [];
      coupons.forEach(r => {
        r.forEach(c => {
          couponArr.push(c);
        });
      });

      var maturityArr = [];
      maturities.forEach(r => {
        r.forEach(c => {
          maturityArr.push(c);
        });
      });

      var intervalArr = [];
      intervals.forEach(r => {
        r.forEach(c => {
          intervalArr.push(c);
        });
      });

      if (maturityArr.length != couponArr.length || 
          maturityArr.length != intervalArr.length || 
          maturityArr.length != priceArr.length){
        throw Error("The lengths of the task's parameters are not equal.")
      }
      var batchTask = {
        need_notify: false,
        use_own_data: false,
        valuation_date: ToDateStr(valuationDate),
        tasks: []
      };
      for (let i = 0; i < maturityArr.length; i++){
        var task = {
          row_id: i.toString(),
          price: priceArr[i],
          coupon: couponArr[i],
          maturity: maturityArr[i],
          interval: intervalArr[i]
        };
        batchTask.tasks.push(task);
      };
      //return JSON.stringify(batchTask);

      // Submit tasks to plutus API, and get result
      if (batchTask.tasks.length <= 0){
        throw Error("No tasks submitted!");
      } else {
        return Rate_Prod_Calculate(batchTask, apiUrl, cellAddress, ['yield_val', 'duration', 'convexity']);
      }
    } catch (error) {
      UnlockSheet();
      return error.toString();
    }
  });
}

/**
 * Floating Rate Bond. 
 * @customfunction 
 * @param {string} valuationDate Valuation Date
 * @param {number[][]} prices Price cells
 * @param {number[][]} maturities Maturity cells
 * @param {number[][]} intervals Coupon Interval cells
 * @param {number[][]} margins Margin cells
 * @param {number[][]} [coupons] Initial Accrual Rate cells
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns 
 * @requiresAddress
 */
 function Floater(valuationDate, prices, maturities, intervals, margins, coupons, invocation) {

  // Lock current sheet.
  return LockSheet().then((res)=>{
    try {
      const apiUrl = hostUrl + "fixedincomeproduct/floater/";
      var cellAddress = invocation.address.split("!");    // sheet1!A9
      var priceArr = [];
      prices.forEach(r => {
        r.forEach(c => {
          priceArr.push(c);
        });
      });

      var maturityArr = [];
      maturities.forEach(r => {
        r.forEach(c => {
          maturityArr.push(c);
        });
      });

      var intervalArr = [];
      intervals.forEach(r => {
        r.forEach(c => {
          intervalArr.push(c);
        });
      });

      var marginArr = [];
      margins.forEach(r => {
        r.forEach(c => {
          marginArr.push(c);
        });
      });

      var couponArr = [];
      if (coupons != null && coupons.length > 0){
        coupons.forEach(r => {
          r.forEach(c => {
            couponArr.push(c);
          });
        });
      }

      if (maturityArr.length != marginArr.length || 
          maturityArr.length != intervalArr.length || 
          maturityArr.length != priceArr.length){
        throw Error("The lengths of the task's parameters are not equal.")
      }
      var batchTask = {
        need_notify: false,
        use_own_data: false,
        valuation_date: ToDateStr(valuationDate),
        tasks: []
      };
      for (let i = 0; i < maturityArr.length; i++){
        var task = {
          row_id: i.toString(),
          price: priceArr[i],
          maturity: maturityArr[i],
          margin: marginArr[i],
          interval: intervalArr[i]
        };
        if (couponArr.length > 0){
          task.coupon = couponArr[i];
        }
        batchTask.tasks.push(task);
      };
      //return JSON.stringify(batchTask);

      // Submit tasks to plutus API, and get result
      if (batchTask.tasks.length <= 0){
        throw Error("No tasks submitted!");
      } else {
        return Rate_Prod_Calculate(batchTask, apiUrl, cellAddress, ['discount_margin', 'duration', 'convexity']);
      }
    } catch (error) {
      UnlockSheet();
      return error.toString();
    }
  });
}

/**
 * Floating Rate Bond with a Floor. 
 * @customfunction 
 * @param {string} valuationDate Valuation Date
 * @param {number[][]} prices Price cells
 * @param {number[][]} maturities Maturity cells
 * @param {number[][]} intervals Interval cells
 * @param {number[][]} margins Margin cells
 * @param {number[][]} [floors] Floor cells
 * @param {number[][]} [coupons] Coupon cells
 * @param {string} [vol_type] VOL type, type should be: SV, SVSLN. Default: "SVSLN"
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns 
 * @requiresAddress
 */
 function Floater_With_Floor(valuationDate, prices, maturities, intervals, margins, floors, coupons, vol_type, invocation) {
  // Lock current sheet.
  return LockSheet().then((res)=>{
    try {
      const apiUrl = hostUrl + "fixedincomeproduct/floater_floor/";
      var cellAddress = invocation.address.split("!");    // sheet1!A9
      var priceArr = [];
      prices.forEach(r => {
        r.forEach(c => {
          priceArr.push(c);
        });
      });

      var maturityArr = [];
      maturities.forEach(r => {
        r.forEach(c => {
          maturityArr.push(c);
        });
      });

      var intervalArr = [];
      intervals.forEach(r => {
        r.forEach(c => {
          intervalArr.push(c);
        });
      });

      var marginArr = [];
      margins.forEach(r => {
        r.forEach(c => {
          marginArr.push(c);
        });
      });

      var floorArr = [];
      if (floors != null && floors.length > 0){
        floors.forEach(r => {
          r.forEach(c => {
            floorArr.push(c);
          });
        });
      }

      var couponArr = [];
      if (coupons != null && coupons.length > 0){
        coupons.forEach(r => {
          r.forEach(c => {
            couponArr.push(c);
          });
        });
      }

      if (maturityArr.length != marginArr.length || 
          maturityArr.length != intervalArr.length || 
          maturityArr.length != priceArr.length){
        throw Error("The lengths of the task's parameters are not equal.")
      }
      var batchTask = {
        need_notify: false,
        use_own_data: false,
        valuation_date: ToDateStr(valuationDate),
        vol_type: vol_type == null ? "SVSLN" : vol_type,
        tasks: []
      };
      for (let i = 0; i < maturityArr.length; i++){
        var task = {
          row_id: i.toString(),
          price: priceArr[i],
          maturity: maturityArr[i],
          margin: marginArr[i],
          interval: intervalArr[i]
        };
        if (floorArr.length > 0){
          task.floor = floorArr[i];
        }
        if (couponArr.length > 0){
          task.coupon = couponArr[i];
        }
        batchTask.tasks.push(task);
      };
      //return JSON.stringify(batchTask);

      // Submit tasks to plutus API, and get result
      if (batchTask.tasks.length <= 0){
        throw Error("No tasks submitted!");
      } else {
        return Rate_Prod_Calculate(batchTask, apiUrl, cellAddress, ['discount_margin', 'duration', 'convexity']);
      }      
    } catch (error) {
      UnlockSheet();
      return error.toString();
    }
  });
}

/**
 * Bermudan Callable Bond. 
 * @customfunction 
 * @param {string} valuationDate Valuation Date
 * @param {number[][]} prices Price cells
 * @param {number[][]} lockouts Lockout cells
 * @param {number[][]} maturities Maturity cells
 * @param {number[][]} coupons Coupon cells
 * @param {number[][]} intervals Interval cells
 * @param {string} [vol_type] VOL type, type should be: SV, SVSLN. Default: "SVSLN"
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns 
 * @requiresAddress
 */
  function Bermudan_Callable(valuationDate, prices, lockouts, maturities, coupons, intervals, vol_type, invocation) {
  
    // Lock current sheet.
    return LockSheet().then((res)=>{
      try {
        const apiUrl = hostUrl + "fixedincomeproduct/berm_callable/";
        var cellAddress = invocation.address.split("!");    // sheet1!A9
        var priceArr = [];
        prices.forEach(r => {
          r.forEach(c => {
            priceArr.push(c);
          });
        });
  
        var lockoutArr = [];
        lockouts.forEach(r => {
          r.forEach(c => {
            lockoutArr.push(c);
          });
        });
        var maturityArr = [];
        maturities.forEach(r => {
          r.forEach(c => {
            maturityArr.push(c);
          });
        });
    
        var couponArr = [];
        coupons.forEach(r => {
          r.forEach(c => {
            couponArr.push(c);
          });
        });
    
        var intervalArr = [];
        intervals.forEach(r => {
          r.forEach(c => {
            intervalArr.push(c);
          });
        });
    
        if (lockoutArr.length != maturityArr.length || 
            lockoutArr.length != couponArr.length || 
            lockoutArr.length != intervalArr.length || 
            lockoutArr.length != priceArr.length){
          throw Error("The lengths of the task's parameters are not equal.")
        }
        var batchTask = {
          need_notify: false,
          use_own_data: false,
          valuation_date: ToDateStr(valuationDate),
          vol_type: vol_type == null ? "SVSLN" : vol_type,
          tasks: []
        };
        for (let i = 0; i < lockoutArr.length; i++){
          var task = {
            row_id: i.toString(),
            price: priceArr[i],
            lockout: lockoutArr[i],
            maturity: maturityArr[i],
            coupon: couponArr[i],
            interval: intervalArr[i]
          };
          batchTask.tasks.push(task);
        };
        //return JSON.stringify(batchTask);
    
        // Submit tasks to plutus API, and get result
        if (batchTask.tasks.length <= 0){
          throw Error("No tasks submitted!");
        } else {
          return Rate_Prod_Calculate(batchTask, apiUrl, cellAddress, ['oas', 'duration', 'convexity', 'yield_maturity', 'yield_call']);
        }
      } catch (error) {
        UnlockSheet();
        return error.toString();
      }
    });
  }

/**
 * Interest Rate Swap. 
 * @customfunction 
 * @param {string} valuationDate Valuation Date
 * @param {string} startDate Start Date
 * @param {number[][]} terms Term cells
 * @param {number[][]} swapRates Swap Rate cells
 * @param {number[][]} intervals Interval cells
 * @param {string[][]} isPayers Payer/Receiver cells, the value should be Payer or Receiver
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns 
 * @requiresAddress
 */
   function Swap(valuationDate, startDate, terms, swapRates, intervals, isPayers, invocation) {
    // Lock current sheet.
    return LockSheet().then((res)=>{
      try {
        const apiUrl = hostUrl + "fixedincomeproduct/swaps/";
        var cellAddress = invocation.address.split("!");    // sheet1!A9
        var termArr = [];
        terms.forEach(r => {
          r.forEach(c => {
            termArr.push(c);
          });
        });
        var swapRateArr = [];
        swapRates.forEach(r => {
          r.forEach(c => {
            swapRateArr.push(c);
          });
        });
    
        var isPayerArr = [];
        isPayers.forEach(r => {
          r.forEach(c => {
            isPayerArr.push(c);
          });
        });
    
        var intervalArr = [];
        intervals.forEach(r => {
          r.forEach(c => {
            intervalArr.push(c);
          });
        });
        if (termArr.length != swapRateArr.length || 
          termArr.length != isPayerArr.length || 
          termArr.length != intervalArr.length){
          throw Error("The lengths of the task's parameters are not equal.")
        }
        var batchTask = {
          need_notify: false,
          use_own_data: false,
          valuation_date: ToDateStr(valuationDate),
          tasks: []
        };
        var sd = ToDateStr(startDate);
        for (let i = 0; i < termArr.length; i++){
          var task = {
            row_id: i.toString(),
            term: termArr[i],
            swap_rate: swapRateArr[i],
            is_payer: isPayerArr[i],
            interval: intervalArr[i],
            start_date: sd
          };
          batchTask.tasks.push(task);
        };
        //return JSON.stringify(batchTask);

        // Submit tasks to plutus API, and get result
        if (batchTask.tasks.length <= 0){
          throw Error("No tasks submitted!");
        } else {
          return Rate_Prod_Calculate(batchTask, apiUrl, cellAddress);
        }
      } catch (error) {
        UnlockSheet();
        return error.toString();
      }
    });
  }

function Cap_Floor(valuationDate, terms, strikes, isCap = "Cap", vol_type = "SVSLN", invocation) {
  // Lock current sheet.
  return LockSheet().then((res)=>{
    try {
      const apiUrl = hostUrl + "fixedincomeproduct/cap_floor/";
      var cellAddress = invocation.address.split("!");    // sheet1!A9

      var termArr = [];
      terms.forEach(r => {
        r.forEach(c => {
          termArr.push(c);
        });
      });

      var strikeArr = [];
      strikes.forEach(r => {
        r.forEach(c => {
          strikeArr.push(c);
        });
      });

      if (termArr.length != strikeArr.length){
        throw Error("The lengths of the task's parameters are not equal.")
      }
      var batchTask = {
        need_notify: false,
        use_own_data: false,
        valuation_date: ToDateStr(valuationDate),
        vol_type: vol_type == null ? "SVSLN" : vol_type,
        tasks: []
      };
      for (let i = 0; i < termArr.length; i++){
        var task = {
          row_id: i.toString(),
          term: termArr[i],
          strike: strikeArr[i],
          is_cap: isCap
        };
        batchTask.tasks.push(task);
      };
      //return JSON.stringify(batchTask);

      // Submit tasks to plutus API, and get result
      if (batchTask.tasks.length <= 0){
        throw Error("No tasks submitted!");
      } else {
        return Rate_Prod_Calculate(batchTask, apiUrl, cellAddress);
      }
    } catch (error) {
      UnlockSheet();
      return error.toString();
    }
  });
}

/**
 * Interest Rate Cap. 
 * @customfunction 
 * @param {string} valuationDate Valuation Date
 * @param {number[][]} terms Term cells
 * @param {number[][]} strikes Strike cells
 * @param {string} [vol_type] VOL type, type should be: SV, SVSLN. Default: "SVSLN"
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns 
 * @requiresAddress
 */
function Cap(valuationDate, terms, strikes, vol_type, invocation) {
  return Cap_Floor(valuationDate, terms, strikes, "Cap", vol_type, invocation);
}
/**
 * Interest Rate Floor. 
 * @customfunction 
 * @param {string} valuationDate Valuation Date
 * @param {number[][]} terms Term cells
 * @param {number[][]} strikes Strike cells
 * @param {string} [vol_type] VOL type, type should be: SV, SVSLN. Default: "SVSLN"
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns 
 * @requiresAddress
 */
 function Floor(valuationDate, terms, strikes, vol_type, invocation) {
  return Cap_Floor(valuationDate, terms, strikes, "Floor", vol_type, invocation);
}

/**
 * European Swaption. 
 * @customfunction 
 * @param {string} valuationDate Valuation Date
 * @param {number[][]} expiries Expiry cells
 * @param {number[][]} strikes Strike cells
 * @param {number[][]} tenors Tenor cells
 * @param {string[][]} isPayers Payer/Receiver cells, the value should be Payer or Receiver
 * @param {string} [vol_type] VOL type, type should be: SV, SVSLN. Default: "SVSLN"
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns 
 * @requiresAddress
 */
function European_Swaption(valuationDate, expiries, strikes, tenors, isPayers, vol_type, invocation) {
  // Lock current sheet.
  console.log(valuationDate);
  return LockSheet().then((res)=>{
    try {
      const apiUrl = hostUrl + "fixedincomeproduct/euro_swptn/";
      var cellAddress = invocation.address.split("!");    // sheet1!A9

      var expiryArr = [];
      expiries.forEach(r => {
        r.forEach(c => {
          expiryArr.push(c);
        });
      });

      var strikeArr = [];
      strikes.forEach(r => {
        r.forEach(c => {
          strikeArr.push(c);
        });
      });

      var tenorArr = [];
      tenors.forEach(r => {
        r.forEach(c => {
          tenorArr.push(c);
        });
      });

      var isPayerArr = [];
      isPayers.forEach(r => {
        r.forEach(c => {
          isPayerArr.push(c);
        });
      });

      if (expiryArr.length != strikeArr.length || 
        expiryArr.length != tenorArr.length || 
        expiryArr.length != isPayerArr.length){
        throw Error("The lengths of the task's parameters are not equal.")
      }
      var batchTask = {
        need_notify: false,
        use_own_data: false,
        valuation_date: ToDateStr(valuationDate),
        vol_type: vol_type == null ? "SVSLN" : vol_type,
        tasks: []
      };
      for (let i = 0; i < expiryArr.length; i++){
        var task = {
          row_id: i.toString(),
          expiry: expiryArr[i],
          strike: strikeArr[i],
          tenor: tenorArr[i],
          is_payer: isPayerArr[i]
        };
        batchTask.tasks.push(task);
      };
      //return JSON.stringify(batchTask);

      // Submit tasks to plutus API, and get result
      if (batchTask.tasks.length <= 0){
        throw Error("No tasks submitted!");
      } else {
        return Rate_Prod_Calculate(batchTask, apiUrl, cellAddress);
      }
    } catch (error) {
      UnlockSheet();
      return error.toString();
    }
  });
}

/**
 * Bermudan Swaption. 
 * @customfunction 
 * @param {string} valuationDate Valuation Date
 * @param {number[][]} lockouts Lockout cells
 * @param {number[][]} tenors Tenor cells
 * @param {number[][]} strikes Strike cells
 * @param {number[][]} callFrequences Call Frequence cells
 * @param {string[][]} isPayers Payer/Receiver cells, the value should be Payer or Receiver
 * @param {string} [vol_type] VOL type, type should be: SV, SVSLN. Default: "SVSLN"
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns 
 * @requiresAddress
 */
function Bermudan_Swaption(valuationDate, lockouts, tenors, strikes, callFrequences, isPayers, vol_type, invocation) {
  // Lock current sheet.
  return LockSheet().then((res)=>{
    try {
      const apiUrl = hostUrl + "fixedincomeproduct/berm_swptn/";
      var cellAddress = invocation.address.split("!");    // sheet1!A9
      var lockoutArr = [];
      lockouts.forEach(r => {
        r.forEach(c => {
          lockoutArr.push(c);
        });
      });

      var strikeArr = [];
      strikes.forEach(r => {
        r.forEach(c => {
          strikeArr.push(c);
        });
      });

      var tenorArr = [];
      tenors.forEach(r => {
        r.forEach(c => {
          tenorArr.push(c);
        });
      });

      var callFrequenceArr = [];
      callFrequences.forEach(r => {
        r.forEach(c => {
          callFrequenceArr.push(c);
        });
      });

      var isPayerArr = [];
      isPayers.forEach(r => {
        r.forEach(c => {
          isPayerArr.push(c);
        });
      });

      if (lockoutArr.length != strikeArr.length || 
        lockoutArr.length != tenorArr.length || 
        lockoutArr.length != callFrequenceArr.length || 
        lockoutArr.length != isPayerArr.length){
        throw Error("The lengths of the task's parameters are not equal.")
      }
      var batchTask = {
        need_notify: false,
        use_own_data: false,
        valuation_date: ToDateStr(valuationDate),
        vol_type: vol_type == null ? "SVSLN" : vol_type,
        tasks: []
      };
      for (let i = 0; i < lockoutArr.length; i++){
        var task = {
          row_id: i.toString(),
          lockout: lockoutArr[i],
          strike: strikeArr[i],
          tenor: tenorArr[i],
          call_freq: callFrequenceArr[i],
          is_payer: isPayerArr[i]
        };
        batchTask.tasks.push(task);
      };
      //return JSON.stringify(batchTask);

      // Submit tasks to plutus API, and get result
      if (batchTask.tasks.length <= 0){
        throw Error("No tasks submitted!");
      } else {
        return Rate_Prod_Calculate(batchTask, apiUrl, cellAddress);
      }
    } catch (error) {
      UnlockSheet();
      return error.toString();
    }
  });
}
CustomFunctions.associate("MBS_PRICE", MBS_Price);
CustomFunctions.associate("MBS_OAS", MBS_OAS);
CustomFunctions.associate("EUROPEAN_CALLABLE", European_Callable);
CustomFunctions.associate("FIXED_RATE_BULLET", Fixed_Rate_Bullet);
CustomFunctions.associate("FLOATER", Floater);
CustomFunctions.associate("FLOATER_WITH_FLOOR", Floater_With_Floor);
CustomFunctions.associate("BERMUDAN_CALLABLE", Bermudan_Callable);
CustomFunctions.associate("SWAP", Swap);
CustomFunctions.associate("CAP", Cap);
CustomFunctions.associate("FLOOR", Floor);
CustomFunctions.associate("EUROPEAN_SWAPTION", European_Swaption);
CustomFunctions.associate("BERMUDAN_SWAPTION", Bermudan_Swaption);